import env from '@beam-australia/react-env';
import React, { useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
} from 'react-google-places-autocomplete';
import { components } from 'react-select';

import { FormFieldProps } from 'shared/interface/checkout.interface';

import { SelectSettings } from '../utils/selectHandler';
const Input = (props, ref) => (
    <components.Input ref={ref} {...props} isHidden={false} />
);

export const AddressSelect = (props: FormFieldProps) => {
    const { form, values } = props;
    const inputRef = useRef(null);

    const [value, setValue] = useState({
        label: '',
        value: '',
    });
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (inputValue && inputValue?.length < 3) {
            form.setFieldValue('address', undefined);
            form.setFieldValue('city', undefined);
        }
    }, [inputValue]);

    useEffect(() => {
        if (values && values.city) {
            setValue({
                label: `${values?.city?.label}, ${values?.address?.label || ''}`,
                value: `${values?.city?.label}, ${values?.address?.label || ''}`,
            });
            setInputValue(
                `${values?.city?.label}, ${values?.address?.label || ''}`,
            );
        }
    }, [values?.city]);

    const onInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            setInputValue(inputValue);
        }
    };

    const onChange = option => {
        let tempCity = '';
        if (option && option?.value) {
            geocodeByPlaceId(option.value.place_id)
                .then(result => {
                    const addressComponents = result[0].address_components;
                    const filteredCities = addressComponents.filter(
                        address_component =>
                            address_component.types.includes('locality') ||
                            address_component.types.includes('postal_town'),
                    );

                    const filteredPostal = addressComponents.filter(
                        address_component =>
                            address_component.types.includes('postal_code') ||
                            address_component.types.includes('postal'),
                    );

                    const postal =
                        filteredPostal.length && filteredPostal[0].long_name;

                    const city =
                        filteredCities.length && filteredCities[0].long_name;

                    tempCity = city;
                    changeCity(city);
                    changePostal(postal);
                })
                .then(() => {
                    setValue({
                        label:
                            `${tempCity}, ${option.value.structured_formatting.main_text}` ||
                            '',
                        value:
                            `${tempCity}, ${option.value.structured_formatting.main_text}` ||
                            '',
                    });
                    setInputValue(
                        option
                            ? `${tempCity}, ${option.value.structured_formatting.main_text}`
                            : '',
                    );
                });

            form.setFieldValue('address', {
                label: option.value.structured_formatting.main_text,
            });
        }
    };

    const changeCity = (cityName: string) => {
        form.setFieldValue('city', {
            label: cityName,
            value: cityName,
        });
    };

    const changePostal = (postal: string) => {
        if (postal && postal?.length > 1) {
            form.setFieldValue('postal', postal);
        }
    };

    const handleFocus = () => {
        const input = inputRef?.current;
        if (input && input.inputRef) {
            input.inputRef.focus();
            input.inputRef.setSelectionRange(
                input.inputRef.value.length,
                input.inputRef.value.length,
            );
        }
    };

    const disabled = !values?.country;

    return (
        <GooglePlacesAutocomplete
            selectProps={{
                ref: inputRef,
                controlShouldRenderValue: false,
                value: value.value,
                onFocus: () => handleFocus(),
                inputValue: inputValue,
                onInputChange: onInputChange,
                onChange: onChange,
                components: {
                    Input,
                },
                ...SelectSettings('address'),
                isDisabled: disabled,
                theme: theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',
                        neutral0: '#0F0F0F',
                        neutral20: '#0F0F0F',
                    },
                }),
                styles: {
                    menu: () => ({
                        background: '#0F0F0F',
                        position: 'absolute',
                        zIndex: 99,
                    }),
                    noOptionsMessage: () => ({
                        display: 'none',
                        background: '#0F0F0F',
                    }),
                    indicatorsContainer: provided => ({
                        ...provided,
                        display: 'none',
                    }),
                    placeholder: provided => ({
                        ...provided,
                        color: 'rgba(235, 235, 245, 0.30)',
                        padding: '0 16px',
                        fontSize: '17px',
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#ffffff',
                        background: '#1c1c1e',
                        height: '44px',
                        borderRadius: '10px',
                        borderColor: state.isFocused
                            ? '#ffffff'
                            : 'transparent',
                        '&:hover': {
                            borderColor: state.isFocused
                                ? '#ffffff'
                                : 'transparent',
                        },
                    }),
                    input: provided => ({
                        ...provided,
                        padding: '0 16px',
                        color: '#ffffff',
                        fontSize: '17px',
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                    option: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                        padding: 0,
                        zIndex: 10,
                        fontWeight: 400,
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: '#ffffff',
                        paddingLeft: 16,
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                },
            }}
            apiKey={`${env('GOOGLE_MAPS_KEY')}&loading=async`}
            minLengthAutocomplete={3}
            debounce={500}
            autocompletionRequest={{
                componentRestrictions: {
                    country: values?.country?.code,
                },
                types: ['route'],
            }}
        />
    );
};
