import { FormFields } from 'shared/interface/utils.interface';
export const globalFields: FormFields[] = [
    {
        position: 0,
        label: 'Country/Region',
        name: 'country',
        type: 'select',
        list: null,
        input: {
            placeholder: 'Enter your destination',
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
    {
        position: 1,
        label: 'City',
        name: 'city',
        type: 'select',
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },

    {
        position: 4,
        label: 'First name',
        name: 'firstName',
        input: {
            placeholder: 'Enter your first name',
            maxLength: 50,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },

    {
        position: 4,
        label: 'Last name',
        name: 'lastName',
        input: {
            placeholder: 'Enter your last name',
            maxLength: 50,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },

    {
        position: 2,
        label: 'Address',
        name: 'address',
        input: {
            maxLength: 256,
            placeholder: 'Street address, building',
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },

    {
        position: 2,
        label: 'Apartment (optional)',
        name: 'apartment',
        input: {
            maxLength: 256,
            placeholder: 'Apartment',
        },
    },

    {
        position: 999,
        hidden: true,
        label: 'Apartment (optional)',
        name: 'building',
        input: {
            maxLength: 256,
            placeholder: 'Apartment',
        },
    },

    {
        position: 7,
        label: 'Phone Number',
        name: 'phone',
        input: {
            prefix: '+',
            placeholder: 'Enter your phone number',
            maxLength: 15,
        },
        rules: [
            {
                required: true,
                message: '',
            },
            {
                pattern: new RegExp(/^[0-9\s]*$/),
                message: '',
            },
        ],
    },

    {
        position: 8,
        label: 'Postal Code',
        name: 'postal',
        input: {
            placeholder: 'Postal Code',
            maxLength: 8,
        },
        rules: [
            {
                required: true,
                message: '',
            },
        ],
    },
];
