import React, { lazy, memo, useEffect } from 'react';
import { Flex } from 'antd';

import { gap } from 'app/config/constants';
import { DeliveryInfoTypes } from 'shared/interface/checkout.interface';
import { Title } from 'shared/ui/Paragraph';
import { Empty } from 'shared/ui/Address';
import { EmptyAddressProps } from 'shared/ui/Address/ui/Empty';
import { Warning } from 'shared/ui/Icons/Warning';
import { Text } from 'shared/ui/Paragraph/ui/Text';

import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { useLazyReadCountriesQuery } from '../../../api/utils.api';

const Unit = lazy(() =>
    import('shared/ui/Layout').then(({ Unit }) => ({
        default: Unit,
    })),
);
const Page = lazy(() =>
    import('shared/ui/Layout').then(({ Page }) => ({
        default: Page,
    })),
);

export const DeliveryInfo = memo((props: DeliveryInfoTypes) => {
    const {
        address,
        state,
        city,
        full_name,
        postal_code,
        country,
        is_filled_delivery,
        phone,
        order_id,
        delivery,
    } = props;

    const [cacheFields, setCacheFields] = useSessionStorage(
        'cacheFields',
        null,
    );

    const [getCountries, { data }] = useLazyReadCountriesQuery();

    const pageTitle = 'Delivery Address';

    useEffect(() => {
        if (delivery && delivery.country) {
            getCountries(null, true);

            const targetCountry = data?.find(
                elem => elem.label === delivery.country,
            );

            try {
                if (cacheFields) {
                    setCacheFields({
                        ...cacheFields,
                        country: {
                            code: targetCountry.value,
                            label: targetCountry.label,
                            value: targetCountry.label,
                            price: targetCountry.price,
                        },
                    });
                } else {
                    setCacheFields({
                        country: {
                            code: targetCountry.value,
                            label: targetCountry.label,
                            value: targetCountry.label,
                            price: targetCountry.price,
                        },
                    });
                }
            } catch (e) {
                /* empty */
            }
        }
    }, [delivery, data]);

    const renderDelivery = (text: string, strong?: boolean) => {
        return (
            <Text weight={strong ? 600 : 400} size={strong ? 17 : 15}>
                {text}
            </Text>
        );
    };

    return (
        <Page>
            <Flex align={'center'} gap={gap - 2}>
                <Title text={pageTitle} level={2} />
                {!is_filled_delivery && <Warning />}
            </Flex>
            {!is_filled_delivery ? (
                <Empty orderId={order_id} page={EmptyAddressProps.order} />
            ) : (
                <Unit>
                    {renderDelivery(full_name, true)}
                    {renderDelivery(phone?.toString())}
                    {renderDelivery(address)}
                    {renderDelivery(postal_code)}
                    {renderDelivery(city)}
                    {state && renderDelivery(state)}
                    {renderDelivery(country)}
                </Unit>
            )}
        </Page>
    );
});
