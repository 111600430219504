import { FormFieldOption } from '../../../shared/interface/checkout.interface';

export const searchByLabel = (input: string, option: FormFieldOption) => {
    return (option?.label ?? '').toLowerCase().includes(input?.toLowerCase());
};

export const sortByLabel = (a: FormFieldOption, b: FormFieldOption) => {
    return (a?.label ?? '')
        .toLowerCase()
        .localeCompare((b?.label ?? '').toLowerCase());
};

export const SelectSettings = (type: string) => {
    const commonSettings = {
        autoFocus: false,
        menuIsOpen: true,
        isSearchable: true,
        isClearable: true,
        blurInputOnSelect: true,
    };

    switch (type) {
        case 'city':
            return {
                placeholder: 'Enter your city',
                ...commonSettings,
            };
        case 'address':
            return {
                placeholder: 'Enter your address',
                ...commonSettings,
            };
        default:
            return {};
    }
};
