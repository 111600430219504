import React, { memo, useEffect, useState } from 'react';
import { Select } from 'antd';
import styled from 'styled-components';

import {
    FormFieldOption,
    FormFieldProps,
} from 'shared/interface/checkout.interface';

import { useLazyReadCountriesQuery } from '../../../api/utils.api';
import useSessionStorage from '../../../shared/hooks/useSessionStorage';
import { searchByLabel, sortByLabel } from '../utils/selectHandler';

export const CountrySelect = memo((props: FormFieldProps) => {
    const { form, values, foreignCallback, foreignValue } = props;

    const [cacheFields] = useSessionStorage('cacheFields', null);
    const [list, setList] = useState([]);

    const [getCountries, { isLoading, data, isSuccess }] =
        useLazyReadCountriesQuery();

    useEffect(() => {
        getCountries(null, true);
    }, []);

    useEffect(() => {
        if (data && isSuccess) {
            const countries = data.map(({ label, price, value }) => ({
                value: label,
                label,
                price,
                code: value,
            }));
            setList(countries);
        }
    }, [data, isSuccess]);

    const handleSelect = (option: FormFieldOption) => {
        if (option.value !== values?.country?.value) {
            form.setFieldValue('city', undefined);
        }
        form.setFieldValue('country', option);
    };

    return (
        <StyledSelect
            placeholder={'Select Country/Region'}
            status={form?.getFieldError('country')?.length > 0 && 'error'}
            loading={isLoading}
            disabled={isLoading}
            showSearch
            filterOption={(input, option) => searchByLabel(input, option)}
            filterSort={(optionA, optionB) => sortByLabel(optionA, optionB)}
            value={
                foreignCallback
                    ? cacheFields?.county
                    : form?.getFieldValue('country')
            }
            onSelect={(_, option) => {
                foreignCallback
                    ? foreignCallback(option)
                    : handleSelect(option);
            }}
            defaultValue={foreignCallback && foreignValue}
            variant={'borderless'}
            size={'large'}
            options={list}
        />
    );
});

const StyledSelect = styled(Select)`
    border-radius: 12px;
    background: #1c1c1e;
    height: 44px;
    font-size: 17px;
`;
