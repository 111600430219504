import React, { memo, useEffect, useRef, useState } from 'react';
import env from '@beam-australia/react-env';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { components } from 'react-select';

import {
    FormFieldOption,
    FormFieldProps,
} from 'shared/interface/checkout.interface';

import { SelectSettings } from '../utils/selectHandler';

const Input = (props, ref) => (
    <components.Input ref={ref} {...props} isHidden={false} />
);

export const CitySelect = memo((props: FormFieldProps) => {
    const { form, values } = props;
    const inputRef = useRef(null);

    const [value, setValue] = useState({
        label: '',
        value: '',
    });
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        if (values?.city) {
            setValue({
                label: `${values.city.label}`,
                value: `${values.city.label}`,
            });
            setInputValue(`${values.city.label}`);
        }
    }, [values]);

    const onInputChange = (inputValue, { action }) => {
        if (action === 'input-change') {
            setInputValue(inputValue);
        }
    };

    const onChange = async (option: FormFieldOption) => {
        setValue({
            label: option.value.structured_formatting.main_text,
            value: option.value.structured_formatting.main_text,
        });
        setInputValue(option.value.structured_formatting.main_text);

        form.setFieldValue('city', {
            label: option.value.structured_formatting.main_text,
            value: option.value.structured_formatting.main_text,
        });
    };

    const handleFocus = () => {
        const input = inputRef?.current;
        if (input && input.inputRef) {
            input.inputRef.focus();
            input.inputRef.setSelectionRange(
                input.inputRef.value.length,
                input.inputRef.value.length,
            );
        }
    };

    const disabled = !values?.country;

    return (
        <GooglePlacesAutocomplete
            selectProps={{
                ref: inputRef,
                controlShouldRenderValue: false,
                value: value.value,
                onFocus: () => handleFocus(),
                inputValue: inputValue,
                onInputChange: onInputChange,
                onChange: onChange,
                components: {
                    Input,
                },
                ...SelectSettings('city'),
                isDisabled: disabled,
                theme: theme => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'hotpink',
                        primary: 'black',
                        neutral0: '#0F0F0F',
                        neutral20: '#0F0F0F',
                    },
                }),
                styles: {
                    menu: () => ({
                        background: '#0F0F0F',
                        position: 'absolute',
                        zIndex: 99,
                    }),
                    noOptionsMessage: () => ({
                        display: 'none',
                        background: '#0F0F0F',
                    }),
                    indicatorsContainer: provided => ({
                        ...provided,
                        display: 'none',
                    }),
                    placeholder: (provided, state) => ({
                        ...provided,
                        display: state.isFocused ? 'none' : undefined,
                        color: 'rgba(235, 235, 245, 0.30)',
                        padding: '0 16px',
                        fontSize: '17px',
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        color: '#ffffff',
                        background: '#1c1c1e',
                        height: '44px',
                        borderRadius: '10px',
                        borderColor: state.isFocused
                            ? '#ffffff'
                            : 'transparent',
                        '&:hover': {
                            borderColor: state.isFocused
                                ? '#ffffff'
                                : 'transparent',
                        },
                    }),
                    input: provided => ({
                        ...provided,
                        padding: '0 16px',
                        color: '#ffffff',
                        fontSize: '17px',
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                    option: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                    }),
                    valueContainer: provided => ({
                        ...provided,
                        color: 'white',
                        background: '#1c1c1e',
                        padding: 0,
                        zIndex: 900,
                    }),
                    singleValue: provided => ({
                        ...provided,
                        color: '#ffffff',
                        paddingLeft: 16,
                        '&:hover': {
                            borderColor: '#ffffff',
                        },
                        '&:active': {
                            borderColor: '#ffffff',
                        },
                    }),
                },
            }}
            apiKey={`${env('GOOGLE_MAPS_KEY')}&loading=async`}
            minLengthAutocomplete={3}
            debounce={500}
            autocompletionRequest={{
                componentRestrictions: {
                    country: values?.country?.code,
                },
                types: ['(cities)'],
            }}
        />
    );
});
