import React, { lazy, useEffect, useState } from 'react';
import { Form as AntForm } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInitData, useLaunchParams } from '@telegram-apps/sdk-react';
import { ReactTagManager } from 'react-gtm-ts';

import Placeholder from 'shared/ui/Placeholder';
import { globalFields } from 'features/Checkout/lib/globalFields';
import { Form } from 'features/Checkout';
import { NumberOrNull } from 'shared/interface/utils.interface';
import { NavigationLoader } from 'shared/ui/Loaders';
import { Footer, Header } from 'wigets/checkout';
import useSessionStorage from 'shared/hooks/useSessionStorage';

import { useCreateDeliveryMutation } from '../../../api/checkout.api';
import { routes } from '../../../app/config/constants';

const Wrapper = lazy(() =>
    import('shared/ui/Layout').then(({ Wrapper }) => ({
        default: Wrapper,
    })),
);
const Screen = lazy(() =>
    import('shared/ui/Layout').then(({ Screen }) => ({
        default: Screen,
    })),
);

export const CheckoutPage = () => {
    const navigate = useNavigate();
    const initData = useInitData(true);
    const launchParams = useLaunchParams(true);

    const [form] = AntForm.useForm();
    const values = AntForm.useWatch([], form);

    const [isSubmittable, setIsSubmittable] = useState(false);
    const [orderId, setOrderId] = useState<NumberOrNull>(null);

    const [searchParams] = useSearchParams();
    const [cacheFields, setCacheFields] = useSessionStorage(
        'cacheFields',
        null,
    );

    const [createDelivery, { isLoading }] = useCreateDeliveryMutation();

    useEffect(() => {
        if (searchParams) {
            setOrderId(+searchParams.get('orderId'));
        }
    }, [searchParams]);

    useEffect(() => {
        if (initData) {
            ReactTagManager.action({
                event: 'order',
                telegram_id: initData?.user.id,
            });
        }
    }, [initData]);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(() => setIsSubmittable(true))
            .catch(() => setIsSubmittable(false));
    }, [form, values]);

    useEffect(() => {
        if (values) {
            setCacheFields(values);
        }
    }, [values]);

    useEffect(() => {
        if (cacheFields) {
            form.setFieldsValue(cacheFields);
        }
    }, []);

    const handleSubmit = async () => {
        if (!orderId) {
            return navigate(-1);
        }

        form.validateFields({ validateOnly: false })
            .then(() => {
                setIsSubmittable(true);
            })
            .catch(() => {
                setIsSubmittable(false);
            });

        const payload = {
            country: values?.country.label,
            city: values?.city.label,
            address: values?.address.label,
            apartment: values?.apartment,
            postal_code: values?.postal,
            street: values?.address.label,
            building: values?.apartment,
            telegram_user_id: initData?.user.id,
            first_name: values?.firstName,
            last_name: values?.lastName,
            full_name: `${values?.firstName} ${values?.lastName}`,
            phone: values?.phone.replace(/\s+/g, ''),

            order_id: searchParams.get('orderId'),
        };

        if (isSubmittable) {
            await createDelivery(payload).then(() => navigate(routes.orders));
        }
    };

    return isLoading ? (
        <NavigationLoader />
    ) : (
        <Screen>
            <Header />

            <Wrapper position={'first'}>
                <Form
                    form={form}
                    values={values}
                    name={'checkout'}
                    fields={globalFields}
                />
            </Wrapper>

            <Placeholder height={launchParams?.platform === 'ios' ? 300 : 96} />
            <Footer
                onClick={handleSubmit}
                orderId={orderId}
                isSubmittable={isSubmittable}
            />
        </Screen>
    );
};
