import type { RouteObject } from 'react-router';
import { defer } from 'react-router-dom';

import { getProducts } from '../../../api/product.api';
import { getCategory } from '../../../api/categories.api';

const routes: RouteObject[] = [
    {
        path: 'categories/:mainId',
        id: 'category',
        loader: async ({ params }) => {
            if (!params.mainId) {
                throw new Error('category is required');
            }
            const mainId = +params.mainId;
            return defer({
                products: getProducts(mainId),
                category: getCategory(mainId),
            });
        },
        lazy: async () => {
            const { CategoriesPage } = await import(
                '../../../pages/categories'
            );
            return {
                Component: CategoriesPage,
            };
        },
    },
];

export default routes;
