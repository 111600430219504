export const ShippingPolicyText =
    'After you place your order, we will ship it at the earliest opportunity. There are no promised delivery times or specific shipping dates. We will keep customers informed in case of any unexpected delays.';

export const ReturnPolicyText =
    'We manage order cancellations, refunds, returns, and re-shipments individually. For help and detailed instructions, please contact our support email at';

interface Sizes {
    label: string;
    key: string;
    chest: string;
    body: string;
}
export const sizes: Sizes[] = [
    /* {
        label: 'XS',
        key: 'XS',
        chest: '56',
        body: '68',
    },*/
    {
        label: 'S',
        key: 'S',
        chest: '59',
        body: '70',
    },
    {
        label: 'M',
        key: 'M',
        chest: '62',
        body: '72',
    },
    {
        label: 'L',
        key: 'L',
        chest: '65',
        body: '74',
    },
    /* {
        label: 'XL',
        key: 'XL',
        chest: '68',
        body: '76',
    },
    {
        label: 'XXL',
        key: 'XXL',
        chest: '71',
        body: '78',
    },*/
];

export const measurements = [
    {
        title: 'Chest',
        text: 'Measure your chest straight across from one side to the other, without wrapping the tape around your body.',
    },
    {
        title: 'Body length',
        text: 'Measure your hand length from the high point of your shoulder (HPS) down to your wrist.',
    },
];

export const dateTemplate = 'MMM D';
